import React from 'react'
import WfccesForm from './components/Form'

function Wfcces() {
  return (
    <div className=' h-screen absolute z-50 w-screen'>
      <WfccesForm/>
    </div>
  )
}

export default Wfcces
